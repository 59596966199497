import { Expo, TweenMax } from 'gsap';
import { isBrowser, isBuildTime } from '../env';
import { registerParallaxEffect } from '../utils/parallax.utils';
import { highPerf } from '../utils/performance.utils';
import tick from '../utils/waiters.utils';
import './decoTricolor.scss';

type TricolorMorphOptions = {
  red: string,
  green: string,
  blue: string,
  duration?: number,
}

const createLayer = () => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('width', '1194');
  svg.setAttribute('height', '834');
  svg.setAttribute('viewBox', `0 0 1194 834`);
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  if (isBrowser) {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        svg.setAttribute('width', '375');
        svg.setAttribute('height', '812');
        svg.setAttribute('viewBox', `0 0 375 812`);
      } else {
        svg.setAttribute('width', '1194');
        svg.setAttribute('height', '834');
        svg.setAttribute('viewBox', `0 0 1194 834`);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
  }
  return svg;
}

export const defaultTricolorPaths = {
  R: 'M948.1 189.05C948.1 189.078 948.078 189.1 948.05 189.1C948.022 189.1 948 189.078 948 189.05C948 189.022 948.022 189 948.05 189C948.078 189 948.1 189.022 948.1 189.05Z',
  G: 'M48.1 640.05C48.1 640.078 48.0776 640.1 48.05 640.1C48.0224 640.1 48 640.078 48 640.05C48 640.022 48.0224 640 48.05 640C48.0776 640 48.1 640.022 48.1 640.05Z',
  B: 'M1092.1 618.05C1092.1 618.078 1092.08 618.1 1092.05 618.1C1092.02 618.1 1092 618.078 1092 618.05C1092 618.022 1092.02 618 1092.05 618C1092.08 618 1092.1 618.022 1092.1 618.05Z',
}

export const makeDecoTricolorController = () => {
  if (isBuildTime) return;
  const existingSvgContainer = document.getElementById('__DecoTricolorContainer');
  if (!existingSvgContainer) {
    const container = document.createElement('div');
    container.setAttribute('id', '__DecoTricolorContainer');
    container.classList.add('DecoTricolorContainer');
    container.classList.add('hidden');

    const layerRed = createLayer();
    const pathRed = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    pathRed.setAttribute('id', 'TRICOLOR_R');
    pathRed.setAttribute('d', defaultTricolorPaths.R);
    layerRed.append(pathRed);

    const layerGreen = createLayer();
    const pathGreen = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    pathGreen.setAttribute('id', 'TRICOLOR_G');
    pathGreen.setAttribute('d', defaultTricolorPaths.G);
    layerGreen.append(pathGreen);

    const layerBlue = createLayer();
    const pathBlue = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    pathBlue.setAttribute('id', 'TRICOLOR_B');
    pathBlue.setAttribute('d', defaultTricolorPaths.B);
    layerBlue.append(pathBlue);

    container.append(layerRed);
    container.append(layerGreen);
    container.append(layerBlue);

    document.body.prepend(container);

    registerParallaxEffect(layerRed, { id: 'TricolorLayerRed', depth: -.15 });
    registerParallaxEffect(layerGreen, { id: 'TricolorLayerGreen', depth: -.19 });
    registerParallaxEffect(layerBlue, { id: 'TricolorLayerBlue', depth: -.23 });

  }
  let isFirstTime = true;
  return {
    morph: (options: TricolorMorphOptions) => new Promise<void>(async resolve => {
      if (isFirstTime) {
        if (highPerf) await tick(1000);
        isFirstTime = false;
        document.getElementById('__DecoTricolorContainer')?.classList.remove('hidden');
      }
      if (highPerf) {
        TweenMax.to('#TRICOLOR_R', options.duration ?? 1, { morphSVG: options.red, ease: Expo.easeOut });
        TweenMax.to('#TRICOLOR_G', options.duration ?? 1, { morphSVG: options.green, ease: Expo.easeOut, delay: .05 });
        TweenMax.to('#TRICOLOR_B', options.duration ?? 1, { morphSVG: options.blue, ease: Expo.easeOut, delay: .1, onComplete: resolve});
      } else {
        document.getElementById('TRICOLOR_R')?.setAttribute('d', document.querySelector(options.red)?.getAttribute('d') ?? defaultTricolorPaths.R);
        document.getElementById('TRICOLOR_G')?.setAttribute('d', document.querySelector(options.green)?.getAttribute('d') ?? defaultTricolorPaths.G);
        document.getElementById('TRICOLOR_B')?.setAttribute('d', document.querySelector(options.blue)?.getAttribute('d') ?? defaultTricolorPaths.B);
      }
    })
  }
}
