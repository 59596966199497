// LinkedIn Insight Tag
import { asyncPrependToContainer, makeElement } from "../utils/dom.utils";
import { log } from "../utils/loggers.utils";

export const LOAD_LINKEDIN_INSIGHT = async id => {

  log('LOAD_LINKEDIN_INSIGHT', id);

  const noscript = makeElement('noscript');
  const img = makeElement('img');
  img.setAttribute('height', 1);
  img.setAttribute('width', 1);
  img.setAttribute('src', `https://px.ads.linkedin.com/collect/?pid=4048809&fmt=gif`);

  const _linkedin_partner_id = "4048809";
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(_linkedin_partner_id);

  (function(l) {
  if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
  window.lintrk.q=[]}
  var s = document.getElementsByTagName("script")[0];
  var b = document.createElement("script");
  b.type = "text/javascript";b.async = true;
  b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
  s.parentNode.insertBefore(b, s);})(window.lintrk);

  asyncPrependToContainer(() => noscript, () => document.body);

}