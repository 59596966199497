import { TweenLite } from 'gsap';
import { action, flow, reaction, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useAppContext } from '../../controllers/app.controller';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import { isFirefox, isIE, isIPad, isWebKit } from '../../utils/browsers.utils';
import joinClassName from '../../utils/className.utils';
import { makeDisposerController } from '../../utils/disposer.utils';
import { addRootClass, hasRootClass, removeRootClass } from '../../utils/dom.utils';
import { reportError } from '../../utils/loggers.utils';
import { useStore } from '../../utils/mobx.utils';
import { highPerf } from '../../utils/performance.utils';
import tick from '../../utils/waiters.utils';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import './CursorController.scss';

type CursorControllerProps = {}

let mounted = false;

/**
 * Must only be used in a browser environment. 
 * Do not render this during build time or SSR.
 */
const CursorController: React.FC<CursorControllerProps> = props => {
  const { UI } = useAppContext();
  const cursorTargetFocusOverlayRef = useObservableRef();
  const s = useStore(() => ({
    disposer: makeDisposerController(),
    showCoordinationLines: false,
    get x() { return UI.cursor.x },
    set x(v) { UI.cursor.x = v },
    get y() { return UI.cursor.y },
    set y(v) { UI.cursor.y = v },
    get xInt() { return Math.round(s.x) },
    get yInt() { return Math.round(s.y) },
    get vw() { return UI.viewport.width },
    get vh() { return UI.viewport.height },
    get scrollbarWidth() { return UI.ui.scrollbarWidth },
    get scrollY() { return UI.viewport.scrollY },
    target: null as Nullable<HTMLAnchorElement | HTMLButtonElement>,
    rect: {
      top: null as Nullable<number>,
      left: null as Nullable<number>,
      width: null as Nullable<number>,
      height: null as Nullable<number>,
      offset: null as Nullable<number>,
    },
    elementIsInteractable: (el: HTMLElement) => {
      const actions = el.nodeName == 'A' || el.nodeName === 'BUTTON';
      if (isIPad) return actions;
      const inputs = (el.nodeName === 'INPUT') || el.nodeName === 'TEXTAREA' || (el.nodeName === 'LABEL' && !!el.getAttribute('for'));
      return actions || inputs;
    },
    animateTricolorPaths: () => {
      Array.from(document.querySelectorAll<SVGPathElement>('.DecoTricolorContainer path')).forEach((path, i) => {
        const deltaX = (UI.cursor.x / UI.viewport.width - .5) * -25 * ((i + 3) / 7);
        const deltaY = (UI.cursor.y / UI.viewport.height - .5) * -25 * ((i + 3) / 7);
        TweenLite.to(path, 1, { x: deltaX, y: deltaY });
      })
      Array.from(document.querySelectorAll<SVGPathElement>('.DecoAxonLines path')).forEach((path, i) => {
        const deltaX = (UI.cursor.x / UI.viewport.width - .5) * -8 * ((i + 3) / 7);
        const deltaY = (UI.cursor.y / UI.viewport.height - .5) * -8 * ((i + 3) / 7);
        TweenLite.to(path, 1, { x: deltaX, y: deltaY });
      })
    },
    handleMousemove: flow(function * (e: MouseEvent) {
      try {
        // TweenLite.to(s, .62, { x: e.clientX, y: e.clientY, ease: Power2.easeOut });
        s.x = e.clientX;
        s.y = e.clientY;
        const target = e.target as HTMLElement;
        if (!target) return;
        if (s.elementIsInteractable(target) && !target.classList.contains('no-rect')) {
          yield tick(50);
          s.target = e.target as HTMLAnchorElement | HTMLButtonElement;
        }
        else s.target = null;
        if (isWebKit) s.animateTricolorPaths();
      } catch(e) {
        handleError(e);
      }
    }),
    updateTargetBoundingBox: action(() => {
      try {
        if (!s.target) {
          s.rect.top = null;
          s.rect.left = null;
          s.rect.width = null;
          s.rect.height = null;
          s.rect.offset = null;
          return;
        }
        const br = s.target.getBoundingClientRect();
        s.rect.top = br.top;
        s.rect.left = br.left;
        s.rect.width = br.width;
        s.rect.height = br.height;
        const offset = s.target.getAttribute('data-rect-offset');
        s.rect.offset = offset ? +offset : 3;
        cursorTargetFocusOverlayRef.current?.style.setProperty(
          'transform',
          `translate(${(s.rect.left === null ? s.x : s.rect.left - (s.rect.offset ?? 0)) + 'px'}, ${(s.rect.top === null ? s.y : s.rect.top - (s.rect.offset ?? 0)) + 'px'}) scale(${((s.rect.width ?? 0) + (s.rect.offset ?? 0) * 2) / s.vw},${((s.rect.height ?? 0) + (s.rect.offset ?? 0) * 2) / s.vh})`
        )
      } catch(e) {
        handleError(e);
      }
    }),
    handleClick: action((e: MouseEvent) => {
      try {
        const target = e.target as HTMLElement;
        if (s.elementIsInteractable(target)) s.target = null;
      } catch(e) {
        handleError(e);
      }
    }),
    handleFocusChange: action(() => {
      try {
        if (document.activeElement?.nodeName === 'A' || document.activeElement?.nodeName === 'BUTTON') {
          s.target = document.activeElement as HTMLAnchorElement | HTMLButtonElement;
        }
      } catch(e) {
        handleError(e);
      }
    })
  }))
  const handleError = (e: Error) => {
    console.warn('Failed to initiate cursor controller. This will not affect functionalities of the website, so errors have been hidden.');
    removeRootClass('CursorControllerActive');
    reportError(e);
    s.disposer.disposer();
  }
  useOnMount(() => {
    (async () => {
      if (mounted) return;
      if ((!highPerf || isFirefox) && !isIE) return;
      if (hasRootClass('CursorControllerActive')) return;
      await when(() => s.vw >= 768);
      try {
        mounted = true;
        window.addEventListener('click', s.handleClick);
        window.addEventListener('mousemove', s.handleMousemove);
        s.disposer.add(reaction(() => s.target, s.updateTargetBoundingBox));
        document.addEventListener('focusin', s.handleFocusChange, true);
        s.disposer.add(() => {
          window.removeEventListener('click', s.handleClick);
          window.removeEventListener('mousemove', s.handleMousemove);
          document.removeEventListener('focusin', s.handleFocusChange);
          addRootClass('CursorControllerActive');
        })
        s.disposer.add(reaction(() => s.scrollY, () => s.target = null));
        addRootClass('CursorControllerActive');
        return s.disposer.disposer
      } catch (e) {
        handleError(e);
      }
    })()
  })
  return <Observer children={() => (
    highPerf ? <ErrorBoundary fallback={() => null}>
      <div className={joinClassName('CursorController', s.target && 'interactable')}>
        <div className="CursorTargetFocusOverlay" ref={cursorTargetFocusOverlayRef} />
      </div>
    </ErrorBoundary> : null
  )} />
}

export default CursorController;