// import { loadScript } from "./utils";

import { asyncPrependToContainer, makeElement } from "../utils/dom.utils";
import { log } from "../utils/loggers.utils";

// Google Tag Manager

export const LOAD_GTAG = (id: string, onLoad?: () => void) => {

  log('LOAD_GTAG', id);

  const noscript = makeElement('noscript');
  const iframe = makeElement('iframe');
  iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${id}`);
  iframe.setAttribute('height', '0');
  iframe.setAttribute('width', '0');
  iframe.setAttribute('style', 'display:none;visibility:hidden');

  noscript.append(iframe);
  asyncPrependToContainer(() => noscript, () => document.body);

  // @ts-ignore
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  // @ts-ignore
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
  j.onload = () => { onLoad?.() };
  // @ts-ignore
  j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer',id)

}
