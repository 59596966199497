import TransitionLink from "gatsby-plugin-transition-link";
import { Power2, TweenLite } from 'gsap';
import React, { ReactNode } from 'react';
import { useGetDataAttributes } from "../../hooks/useGetDataAttributes.hook";
import { TransitionTrigger } from "../../types/transitions.types";
import joinClassName from "../../utils/className.utils";
import { getScrollY } from "../../utils/dom.utils";
import { highPerf } from "../../utils/performance.utils";
import { AlwaysFalseFn } from "../../utils/ramdaEquivalents.utils";

const AnimatedLinkTrigger: TransitionTrigger = args => {
  // log(args.exit);
  // log(args.node);
  const duration = highPerf ? (getScrollY() > window.innerHeight * .62 ? 0 : args.exit?.length ?? args.entry?.length ?? .5) : 0;
  if (duration === 0) TweenLite.set(args.node, { opacity: 0 });
  TweenLite.fromTo(args.node, duration, { opacity: 1 }, {
    opacity: 0,
    ease: Power2.easeInOut,
    x: -24,
  })
}

interface AnimatedLinkProps {
  className?: string,
  to: string,
  title: string,
  length?: number,
  onClick?: () => void,
  activeClassChecker?: () => boolean,
  target?: string,
  rel?: string,
  children?: ReactNode
}

/**
 * A wrapper around the TransitionLink component, provides basic fading effect for exiting pages.
 */
export const AnimatedLink = React.forwardRef((props: React.PropsWithChildren<AnimatedLinkProps>, ref) => {
  const dataAttr = useGetDataAttributes(props);
  const commonAttr = {
    className: joinClassName(props.className, (props.activeClassChecker ?? AlwaysFalseFn)() ? 'active' : undefined),
    to: props.to,
    title: props.title,
    length: props.length,
    children: props.children,
    onClick: props.onClick,
    activeClassName: "active",
    target: props.target,
    rel: props.rel,
    ...dataAttr,
  }
  // if (enableHighPerfAnim) {
    return <TransitionLink
      {...commonAttr}
      ref={ref}
      exit={{
        length: highPerf ? (props.length ?? .38) : 0,
        trigger: AnimatedLinkTrigger
      }}
    />
  // } else {
  //   return <Link {...commonAttr} />
  // }
})