// if (process.env.NODE_ENV === 'development') {
//   process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0'
// }
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import { WrapPageElementBrowserArgs } from "gatsby";
import { Observer } from "mobx-react-lite";
import React from "react";
import initReactFastClick from 'react-fastclick';
import configureMobx from "../src/@configureMobx";
import applyPolyfills from "../src/@polyfills";
import AnalyticsManager from "../src/components/AnalyticsManager/AnalyticsManager";
import CursorController from "../src/components/CursorController/CursorController";
import DialogStack from "../src/components/DialogStack/DialogStack";
import Symbols from "../src/components/Symbols";
import { AppContext, AppController } from "../src/controllers/app.controller";
import { detectBrowsers } from "../src/utils/browsers.utils";

applyPolyfills();
configureMobx();
detectBrowsers();
initReactFastClick();

// if (isBrowser && process.env.NODE_ENV === 'production') Sentry.init({
//   dsn: "https://5ebe11845822447f9c09b860004a8f03@o165875.ingest.sentry.io/5743081",
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

export const wrapPageElement = (args: WrapPageElementBrowserArgs) => {
  const { props, element } = args;
  return <Observer children={() => (
    <AppContext.Provider value={AppController} {...props}>
      <Symbols />
      { element }
      <CursorController />
      <AnalyticsManager />
      <DialogStack />
    </AppContext.Provider>
  )} />
}

export { };
