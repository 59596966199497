import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject } from '../../@types';
import { runAndIgnoreError } from '../../utils/loggers.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { isObject, isString } from '../../utils/typeChecks.utils';
import ErrorList from './ErrorList';
import './ErrorRenderer.scss';

interface ErrorRendererProps {
  e?: string | Error | null | any,
  error?: string | Error | null | any,
  // TODO
  messageIsUserFriendly?: boolean,
}

const defaultMessage = 'An unknown error occured.';

const ErrorRenderer: React.FC<ErrorRendererProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    errorMessage: defaultMessage,
    details: {} as AnyObject,
    get originalError() {
      return p.error || p.e;
    },
    get errorDetails() {
      return s.originalError instanceof Object ? 'response' in s.originalError ? s.originalError.response : s.originalError : s.originalError;
    },
    get shouldRenderDetails() {
      return Object.keys(s.details).length > 0;
    }
  }));
  
  runAndIgnoreError(action(() => {
    const e = (isObject(s.errorDetails) && s.errorDetails?.data ? s.errorDetails?.data : s.errorDetails);
    s.errorMessage = (isString(e) ? e : (e?.message || e?.error)) ?? s.originalError?.message ?? 'Unknown Error';
    if (isObject(e) && 'errors' in e) {
      Object.assign(s.details, e?.errors);
    }
  }))

  return <Observer children={() => (
    <div className="ErrorRenderer">
      <p>{s.errorMessage}</p>
      { s.shouldRenderDetails && <ErrorList errors={s.details} />}
    </div>
  )} />

}

export default ErrorRenderer;